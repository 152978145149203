.border-bottom{
    border-bottom: 1px solid #c6c6c6;
}
.border-top{
    border-top: 1px solid #c6c6c6;
}

.app.teal-light mat-row, .app.teal-light mat-header-row, .app.teal-light mat-footer-row, .app.teal-light th.mat-header-cell, .app.teal-light td.mat-cell, .app.teal-light td.mat-footer-cell{
    border: 1px solid #cecece;
    padding: 10px;
    text-align: center;
}