@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://cdn.quilljs.com/1.2.2/quill.snow.css');
@import url('https://cdn.quilljs.com/1.2.2/quill.bubble.css');

// indigo-pink, deeppurple-amber, purple-green and pink-bluegrey.
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

// @import '~bootstrap/scss/bootstrap';

@import "app/theme/styles/base";
@import "app/theme/styles/spaces";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";
@import "app/theme/styles/additionalClass";
@import "app/theme/styles/rtl";
@import "app/theme/styles/hijriDatePicker";
@import "./assets/fontawesome/scss/fontawesome.scss";
@import "./assets/fontawesome/scss/solid.scss";
@import "./assets/fontawesome/scss/brands.scss";
@import "./assets/fontawesome/scss/regular.scss";


@import '../node_modules/@syncfusion/ej2-base/styles/material.scss';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.scss';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.scss';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.scss';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.scss';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.scss';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.scss';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.scss';  
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/material.scss';


