.form-control {
    display: block;
    width: 100%;
    // height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .hijriInput {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    flex: 1 1 auto;
    width: -webkit-fill-available;
    min-width: 0;
    margin-bottom: 0;
  }
  
  .input-group-append {
    margin-left: -1px;
  }
  
  .input-group-prepend, .input-group-append {
    display: flex;
  }
  
  
  .input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text, .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn, .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text, .input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn, .input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text, .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
  }
  
  .input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .input-group-prepend .btn, .input-group-append .btn {
    position: relative;
    z-index: 2;
  }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
  }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
    cursor: pointer;
  }
  .btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  .btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .dropdown-menu.show {
    display: block;
    position: fixed !important;
    top: 0;
    left: 0;
    transform: translate(calc(50vw - 50%), calc(50vh - 50%)) !important;
  }
  
  
  ngb-datepicker.dropdown-menu {
    padding: 0;
  }
  ngb-datepicker.dropdown-menu {
    background: #fff;
    padding: 0;
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }
  
  ngb-datepicker {
    border: 1px solid #dfdfdf;
    border-radius: .25rem;
    display: inline-block;
  }
  ngb-datepicker {
    border: 1px solid #dfdfdf;
    border-radius: .25rem;
    display: inline-block;
  }
  
  
  .ngb-dp-header {
    border-bottom: 0;
    border-radius: .25rem .25rem 0 0;
    padding-top: .25rem;
    background-color: #f8f9fa;
    background-color: var(--light);
  }
  
  .ngb-dp-months {
    display: -ms-flexbox;
    display: flex;
  }
  
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  
  ngb-datepicker-navigation {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  
  
  .ngb-dp-arrow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding-right: 0;
    padding-left: 0;
    margin: 0;
    width: 2rem;
    height: 2rem;
  }

  .input-group {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;
  }

  .form-group {
      margin-bottom: 1rem;
  }

  .ngb-dp-navigation-select {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 1 9rem;
      flex: 1 1 9rem;
  }

  ngb-datepicker-navigation-select>.custom-select {
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      padding: 0 .5rem;
      font-size: .875rem;
      height: 1.85rem;
  }

  .custom-control-label::before, .custom-file-label, .custom-select {
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .custom-select {
      display: inline-block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 1.75rem 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      vertical-align: middle;
      // background: #fff url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e) right 0.75rem center/8px 10px no-repeat;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
  }

  ngb-datepicker-navigation-select>.custom-select {
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      padding: 0 .5rem;
      font-size: .875rem;
      height: 1.85rem;
  }
  .custom-control-label::before, .custom-file-label, .custom-select {
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .custom-select {
      display: inline-block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 1.75rem 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      vertical-align: middle;
      // background: #fff url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e) right 0.75rem center/8px 10px no-repeat;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
  }

.input-group > .form-control, .input-group > .form-control-plaintext, .input-group > .custom-select, .input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.ngb-dp-month:first-child .ngb-dp-week {
  padding-left: .25rem;
}

.ngb-dp-month:last-child .ngb-dp-week {
  padding-right: .25rem;
  font-size: 11px;
}

.ngb-dp-day, .ngb-dp-week-number, .ngb-dp-weekday {
  width: 2rem;
  height: 2rem;
}

.ngb-dp-weekday {
  color: #5bc0de;
  color: var(--info);
}
.ngb-dp-week-number, .ngb-dp-weekday {
  line-height: 2rem;
  text-align: center;
  font-style: italic;
}
.ngb-dp-weekday {
  width: 2.1rem!important;
}

ngb-datepicker-month {
    display: block;
    pointer-events: auto;
}
.ngb-dp-header {
  border-bottom: 0;
  border-radius: .25rem .25rem 0 0;
  padding-top: .25rem;
  background-color: #f8f9fa;
  background-color: var(--light);
}

input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.ngb-dp-weekdays {
    border-bottom: 1px solid rgba(0,0,0,.125);
    border-radius: 0;
    background-color: #f8f9fa;
    background-color: var(--light);
}

hijri-date-picker.ng-star-inserted{
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.ngb-dp-header{
  direction: ltr !important;
}
.btn{
  background: #ececec;
}